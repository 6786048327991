<template>
    <v-card style="border-radius: 10px">
      <v-container fluid>
          <v-layout row wrap>
              <v-flex xs12 md8 class="">
                <v-layout row class="grey--text darken-2">
                    <v-flex xs12>
                        <v-text-field class="pa-0"
                            id="current_password" v-model="current_password" ref="current_password"
                            label="Enter Current Password"
                            :rules="[v => !!v || 'Your Current Password is required (at least 8 characters)']"
                            outline small
                            color="teal"
                            min="8"
                            :append-icon="e1 ? 'visibility' : 'visibility_off'"
                            @click:append="() => (e1 = !e1)"
                            :type="e1 ? 'password' : 'text'"
                            counter
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row class="grey--text darken-2">
                    <v-flex xs12 >
                        <v-text-field class="pa-0"
                            id="new_password" v-model="new_password" ref="new_password"
                            label="Enter New Password"
                            :rules="[v => !!v || 'Your New Password is required (at least 8 characters)']"
                            outline
                            color="teal"
                            min="8"
                            :append-icon="e2 ? 'visibility' : 'visibility_off'"
                            @click:append="() => (e2 = !e2)"
                            :type="e2 ? 'password' : 'text'"
                            counter
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row class="grey--text darken-2">
                    <v-flex xs12 >
                        <v-text-field class="pa-0"
                            id="new_password_again" v-model="new_password_again" ref="new_password_again"
                            label="Confirm New Password "
                            :rules="[v => !!v || 'A confirmation for new password is required (at least 8 characters)']"
                            outline
                            color="teal "
                            min="8"
                            :append-icon="e3 ? 'visibility' : 'visibility_off'"
                            @click:append="() => (e3 = !e3)"
                            :type="e3 ? 'password' : 'text'"
                            counter
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <div class="text-xs-center">
                    <v-btn
                        color="primary" :disabled="isBtnDisabled"
                        class="me-3 mt-3"
                        @click="updateAccountDetails()"
                    >
                        Save changes
                    </v-btn>
                </div>
              </v-flex>
              <v-flex md4 class="hidden-sm-and-down">
                    <v-img
                        :src="require('@/assets/pose-m-1.png')"
                        aspect-ratio="2"  
                        contain
                        height="200"
                        width="250"
                        style="cursor: pointer"
                    ></v-img>
              </v-flex>
          </v-layout>
      </v-container>
    </v-card>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                isBtnDisabled: false,
                e1: true,
                e2: true,
                e3: true,

                current_password: "",
                new_password: "",
                new_password_again: "",
              
            }
        },
        mounted(){
            // executes these after the page has been mounted
            this.$store.commit('setCloseURL', "/")
        },
        
        methods:{
            async updateAccountDetails(){
                console.log('updateAccountDetails')
                if(this.current_password.length < 8){
                    this.$store.commit('setSnackBarMessage', "Password is too short, password needs to be at least 8 characters")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.password.focus()  // this causes a an auto fucos to the element
                }
                else if(this.new_password.length < 8){
                    this.$store.commit('setSnackBarMessage', "New password is too short, needs to be at least 8 characters")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.new_password.focus()  // this causes a an auto fucos to the element
                }
                else if(this.new_password_again.length < 8){
                    this.$store.commit('setSnackBarMessage', "New password again is too short, needs to be at least 8 characters")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.new_password_again.focus()  // this causes a an auto fucos to the element
                }
                else {
                    this.isBtnDisabled = true
                    this.loading = true
                    const formData = {
                        password: this.current_password,
                        new_password: this.new_password,
                        new_password_again: this.new_password_again,
                    }

                    await axios
                        .post('/api/v1/home/update_account_details/password/', formData)
                        .then(response => {
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)
                            this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                            if (response.data[0].category === "success"){
                                this.$router.push('/user_profile/account')
                            }
                            this.isBtnDisabled = false
                        })
                        .catch(error => {
                            if (error.response){
                                for (const property in error.response.data){
                                    this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                                    this.$store.commit('setSnackBarColor', "red darken-4")
                                    this.$store.commit('activateSnackBar', true)
                                }
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                    this.loading = false
                }

            },
        },
    }
</script>
